export const NO_PERMISSION_URL = `/403`;
export const NOT_FOUND_URL = `/404`;
export const WELCOME_URL = `/welcome`;

export const MAIN_PAGE_URL = `/`;
export const DASHBOARD_URL = `/dashboard`;
export const LOGIN_PAGE_URL = `/dang-nhap-ctv`; 
export const REGISTER_PAGE_URL = `/register`;
export const RESEND_LINK_ACTIVE_PAGE_URL = `/resend-link-active`;
export const FORGOT_PASSWORD_PAGE_URL = `/forgot-password`;
// export const FREE_COURSE_PAGE_URL = `/free-course`;
// export const FREE_COURSE_DETAIL_PAGE_URL = `/free-course-detail`;
// export const FEE_COURSE_PAGE_URL = `/fee-course`;
// export const FEE_COURSE_DETAIL_PAGE_URL = `/fee-course-detail`;

export const PROFILE_PAGE_URL = `/profile`;
export const CHANGE_PASSWORD_PAGE_URL = `/change-password`;
export const HISTORY_LOG_PAGE_URL = `/history-log`;
export const PAYMENT_HISTORY_PAGE_URL = `/payment-history`;

// COURSE
export const COURSE_DASHBOARD_PAGE_URL = `/course-dashboard`;
export const COURSE_CATEGORY_LIST_PAGE_URL = `/course-category-list`;
export const COURSE_INDEX_LIST_PAGE_URL = `/course-index-list`;
export const COURSE_COMMENT_LIST_PAGE_URL = `/course-comment-list`;
export const COURSE_CONTACT_WEBINAR_LIST_PAGE_URL = `/course-contact-webinar-list`;
export const COURSE_QUESTION_LIST_PAGE_URL = `/course-question-list`;
export const SCHOOL_IT_IN_VIETNAM_LIST_PAGE_URL = `/school-it-in-vietnam-list`;
export const COMPANY_IT_IN_VIETNAM_LIST_PAGE_URL = `/company-it-in-vietnam-list`;
export const MARKET_IT_IN_VIETNAM_LIST_PAGE_URL = `/market-it-in-vietnam-list`;
export const MARKET_IT_IN_ABROAD_LIST_PAGE_URL = `/market-it-in-abroad-list`;

// RULE
export const PROCESS_DOCUMENT_LIST_PAGE_URL = `/process-document-list`;

// JOB
export const JOB_DESCRIPTION_LIST_PAGE_URL = `/job-description-list`;
export const JOB_DESCRIPTION_CONTACT_LIST_PAGE_URL = `/job-description-contact-list`;

// BLOG
export const BLOG_DASHBOARD_PAGE_URL = `/blog-dashboard`;
export const BLOG_CATEGORY_LIST_PAGE_URL = `/blog-category-list`;
export const BLOG_POST_LIST_PAGE_URL = `/blog-post-list`;
export const BLOG_POST_EDIT_PAGE_URL = `/blog-post-edit`;
export const BLOG_POST_LIST_BY_CATEGORY_PAGE_URL = `/blog-post-list-by-category`;
export const BLOG_POST_LIST_VIEW_MOST_PAGE_URL = `/blog-post-list-view-most`;
export const BLOG_POST_COMMENT_LIST_PAGE_URL = `/blog-post-comment-list`;

// NEWS
export const NEWS_DASHBOARD_PAGE_URL = `/news-dashboard`;
export const NEWS_CATEGORY_LIST_PAGE_URL = `/news-category-list`;
export const NEWS_POST_LIST_PAGE_URL = `/news-post-list`;
export const NEWS_POST_EDIT_PAGE_URL = `/news-post-edit`;
export const NEWS_POST_LIST_BY_CATEGORY_PAGE_URL = `/news-post-list-by-category`;
export const NEWS_POST_LIST_VIEW_MOST_PAGE_URL = `/news-post-list-view-most`;

// SHOP
export const SHOP_DASHBOARD_PAGE_URL = `/shop-dashboard`;
export const SHOP_CATEGORY_LIST_PAGE_URL = `/shop-category-list`;
export const SHOP_TYPE_CATEGORY_LEVEL2_PAGE_URL = `/shop-type-category-level2-list`;
export const SHOP_PRODUCT_LIST_PAGE_URL = `/shop-product-list`;
export const SHOP_PRODUCT_DELETED_LIST_PAGE_URL = `/shop-product-deleted-list`;
export const SHOP_PRODUCT_EDIT_PAGE_URL = `/shop-product-edit`;
export const SHOP_ORDER_PAGE_URL = `/shop-order-list`;
export const SHOP_SALE_STAFF_LIST_PAGE_URL = `/shop-sale-staff-list`;
export const SHOP_OUR_CUSTOMER_LIST_PAGE_URL = `/shop-our-customer-list`;
export const SHOP_INTRO_SLIDE_LIST_PAGE_URL = `/shop-intro-slide-list`;
export const SHOP_CERTIFICATE_LIST_PAGE_URL = `/shop-certificate-list`;
export const SHOP_CONTACT_LIST_PAGE_URL = `/shop-contact-list`;
export const SHOP_CUSTOMER_REVIEW_LIST_PAGE_URL = `/shop-customer-review-list`;
export const SHOP_SUPPORT_INFO_LIST_PAGE_URL = `/shop-support-info-list`;
export const SHOP_QUICK_SUPPORT_INFO_LIST_PAGE_URL = `/shop-quick-support-info-list`;
export const SHOP_HOTLINE_INFO_LIST_PAGE_URL = `/shop-hotline-info-list`;
export const SHOP_SOCIAL_INFO_LIST_PAGE_URL = `/shop-social-info-list`;
export const SHOP_PAYMENT_INFO_LIST_PAGE_URL = `/shop-payment-info-list`;
export const SHOP_BANK_PAYMENT_INFO_LIST_PAGE_URL = `/shop-bank-payment-info-list`;
export const SHOP_FOOTER1_LINK_INFO_LIST_PAGE_URL = `/shop-footer1-link-info-list`;
export const SHOP_FOOTER2_LINK_INFO_LIST_PAGE_URL = `/shop-footer2-link-info-list`;
export const SHOP_STATIC_PAGE_LIST_PAGE_URL = `/shop-static-page-list`;
export const SHOP_CARE_SERVICE_LIST_PAGE_URL = `/shop-care-service-list`;
export const SHOP_SERVICE_DETAIL_LIST_PAGE_URL = `/shop-service-detail-list`;
export const SHOP_AVAILABLE_TECHNOLOGY_PAGE_URL = `/shop-available-technology-list`;
export const SHOP_WHY_CHOOSE_US_PAGE_URL = `/shop-why-choose-us-list`;
export const SHOP_MY_CUSTOMER_PAGE_URL = `/shop-my-customer-list`;
export const SHOP_PRODUCT_OF_CUSTOMER_LIST_PAGE_URL = `/shop-product-of-customer-list`;
export const SHOP_QUESTION_AND_ANSWER_PAGE_URL = `/shop-question-and-answer`;
export const SHOP_OUR_TEAM_PAGE_URL = `/shop-our-team`;
export const SHOP_GALLERY_VIDEO_CATEGORY_PAGE_URL = `/shop-gallery-video-category`;
export const SHOP_GALLERY_VIDEO_PAGE_URL = `/shop-gallery-video`;
export const SHOP_GALLERY_IMAGE_CATEGORY_PAGE_URL = `/shop-gallery-image-category`;
export const SHOP_GALLERY_IMAGE_PAGE_URL = `/shop-gallery-image`;
export const SHOP_PRODUCT_COMMENT_LIST_PAGE_URL = `/shop-product-comment-list`;


// COLLABORATOR
export const COLLABORATOR_DASHBOARD_PAGE_URL = `/collaborator-dashboard`;
export const COLLABORATOR_LIST_PAGE_URL = `/collaborator-list`;
export const COLLABORATOR_PROGRAM_PAGE_URL = `/collaborator-program`;
export const COLLABORATOR_INTRODUCE_CUSTOMER_PAGE_URL = `/collaborator-introduce-customer-list`;
export const COLLABORATOR_ME_PAGE_URL = `/collaborator-me`;
export const COLLABORATOR_DESIGNER_PAGE_URL = `/collaborator-designer`;
export const COLLABORATOR_PAYMENT_PAGE_URL = `/collaborator-payment`;
export const COLLABORATOR_ME_DASHBOARD_PAGE_URL = `/collaborator-me-dashboard`;
export const COLLABORATOR_ME_INCOME_PAGE_URL = `/collaborator-me-income`;
export const COLLABORATOR_REVIEW_PAGE_URL = `/collaborator-review`;

// =========================================================

export const HOME_PAGE_URL = `https://gooup1.vn`;
export const TERM_OF_SERVICE_PAGE_URL = HOME_PAGE_URL + `/term-of-service`;
export const _PAGE_URL = `https://gooup1.vn`;
export const COMPANY_NAME = `GOOUP1 GROUP`;
export const APP_NAME = `GUP`;
export const AVATAR_DEFAULT = `/assets/images/avatar_default.png`;
